import React, { useContext, useEffect } from 'react';

import LocalizedStrings from 'react-localization';

import { Clients, Layout } from '../components';
import { GlobalContext } from '../context/GlobalContextProvider';

const strings = new LocalizedStrings({
    en: {
        clients: 'Clients',
        selectClient: 'Select a client form the list below',
    },
    de: {
        clients: 'Kunden',
        selectClient: 'Wählen Sie einen Kunden aus der unten stehenden Liste aus',
    },
});

const IndexPage = () => {
    const context = useContext(GlobalContext);
    useEffect(() => {
        context.setActiveClient(undefined);
        context.setActiveEvent(undefined);
    });
    return (
        <Layout title={strings.clients} subTitle={strings.selectClient}>
            <Clients />
        </Layout>
    );
};

export default IndexPage;
